<template>
  <v-list-item @click="$emit('update:selectedMemberId', member.userId)">
    <v-list-item-action>
      <v-icon :color="color">mdi-check-circle</v-icon>
    </v-list-item-action>

    <v-list-item-content>
      <v-list-item-title class="mb-2 font-weight-bold">
        {{ userName }}
        <span class="ml-2 subtitle-2 grey--text text--darken-1">
          {{ userEmail }}
        </span>
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ organizationName }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    selectedMemberId: {
      type: Number,
      default: 0
    },
    member: {
      type: Object,
      default: () => ({
        // organizationName 팀이름
        // jobGrade 직급
        // jobTitle 직책
      })
    }
  },
  computed: {
    color() {
      const { userId } = this.member;
      if (this.selectedMemberId !== userId) return "";
      return "primary";
    },
    userName() {
      const { userName = "" } = this.member;
      return userName;
    },
    userEmail() {
      const { userEmail = "" } = this.member;
      return userEmail;
    },
    organizationName() {
      const { organizationName, jobGrade, jobTitle } = this.member;
      return [organizationName, jobGrade, jobTitle].filter(Boolean).join(" / ");
    }
  }
};
</script>
