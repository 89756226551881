<template>
  <div>
    <v-list two-line class="py-0">
      <Member
        v-for="member in members"
        :key="member.id"
        :member="member"
        :selectedMemberId.sync="selectedMemberId"
      />
    </v-list>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Member from "./Member.vue";

export default {
  components: { Member },
  async mounted() {
    const { id } = this.params?.project || {};
    await this.getProjectDetail(id);

    const { members = [] } = this.project;
    const master = members.find(m => m.authority === "MASTER");
    const filteredMembers = members.filter(
      m => m.authority !== "MASTER" && m.joinStatus === "ACCEPTED"
    );
    this.masterId = master.userId;
    this.selectedMemberId = master.userId;
    this.members = [master, ...filteredMembers];
  },
  data() {
    return { members: [], masterId: 0, selectedMemberId: 0 };
  },
  computed: {
    ...mapState("flowDialog", ["params", "isConfirmed"]),
    ...mapState("flowProjects", ["projects"]),
    project() {
      const { id } = this.params?.project || {};
      return this.projects.find(p => p.id === id) || {};
    }
  },
  watch: {
    isConfirmed(isConfirmed) {
      if (!isConfirmed) return;

      this.confirm();
    }
  },
  methods: {
    ...mapMutations("flowDialog", ["SET_CONFIRMED", "CLOSE_DIALOG"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    ...mapActions("flowProjects", ["getProjectDetail"]),
    ...mapActions("flowProjects", ["delegateProject"]),
    async confirm() {
      const { selectedMemberId: memberId } = this;
      if (this.masterId == memberId) return this.CLOSE_DIALOG();
      if (!memberId) {
        const message = "프로젝트를 위임할 멤버를 선택해주세요.";
        return this.openSnackbar({ message, type: "ERROR" });
      }

      const { id: projectId } = this.project;
      const { userName } = this.members.find(m => m.userId == memberId);
      this.delegateProject({ memberId, projectId, userName });
    }
  }
};
</script>
